import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { BattlestationState } from "@/State/BattlestationState";
import { Button } from "@/Components/ui/button";
import { Clock, Plus, X } from "lucide-react";
import { useWindowSizeThreshold } from "@/Hooks/useWindowSizeThreshold";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { Link } from "@inertiajs/react";
import { route } from "ziggy-js";
import { Collapsible, CollapsibleContent } from "@/Components/ui/collapsible";

export const BattlestationCreateSetupBanner = () => {
  const [hideCreateSetupBanner, setHideCreateSetupBanner] = useAtom(
    BattlestationState.hideCreateSetupBanner,
  );

  const isBattlestationRoute = route().current("battlestation.show");

  // show the banner immediately if the user isn't viewing the battlestation route,
  // e.g. they're viewing the register modal over the top of the battlestation page
  const [open, setOpen] = useState(!hideCreateSetupBanner && !isBattlestationRoute);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!hideCreateSetupBanner && isBattlestationRoute) {
        setOpen(true);
      }
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const isSmall = useWindowSizeThreshold(Breakpoint.SM);

  const DismissIcon = isSmall ? Clock : X;

  return (
    <Collapsible
      open={open}
      className="fixed bottom-4 left-1/2 z-40 flex w-full -translate-x-1/2 justify-center px-4 sm:px-0">
      <CollapsibleContent className="w-full duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-bottom-[5%] data-[state=open]:slide-in-from-bottom-[5%] sm:w-auto">
        <div className="flex flex-col items-center justify-between gap-2 rounded-xl border bg-background/80 p-2 backdrop-blur-md sm:flex-row sm:justify-start sm:gap-6">
          <div className="flex w-full items-center gap-4">
            <Link href={route("register")} className="w-full">
              <Button className="flex w-full gap-1">
                <Plus className="size-4" />
                <span>Create your setup</span>
              </Button>
            </Link>
            <div className="hidden shrink-0 text-sm sm:block">
              Have a setup? Showcase it to the world.
            </div>
          </div>
          <Button
            variant={isSmall ? "transparent" : "transparentGhost"}
            size={isSmall ? "default" : "icon"}
            onClick={() => {
              setHideCreateSetupBanner(true);
              setOpen(false);
            }}
            className="flex w-full gap-1.5 sm:aspect-square sm:w-auto">
            <DismissIcon className="size-4" />
            <span className="sm:hidden">Maybe later</span>
          </Button>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
