import { atomWithStorage, createJSONStorage } from "jotai/utils";

const sessionJSONStorage = createJSONStorage(() => sessionStorage);
const localJSONStorage = createJSONStorage(() => localStorage);

export const BattlestationState = {
  hideTutorialOnce: atomWithStorage("tutorials.battlestation.editing", false, sessionJSONStorage, {
    getOnInit: true,
  }),
  hideTutorialForever: atomWithStorage("tutorials.battlestation.editing", false, localJSONStorage, {
    getOnInit: true,
  }),
  hideCreateSetupBanner: atomWithStorage(
    "banners.battlestation.createSetup",
    false,
    sessionJSONStorage,
    {
      getOnInit: true,
    },
  ),
};
