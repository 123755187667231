import React, { ReactNode, useMemo } from "react";
import { Navbar, NavigationItem, UserNavigationItem } from "@/Components/Organisms/Navbar/Navbar";
import { useUser } from "@/Hooks/useUser";
import { route } from "ziggy-js";
import ReactTooltip from "react-tooltip";
import { useAbilities } from "@/Hooks/useAbilities";
import { BaseLayout } from "@/Layouts/BaseLayout";
import { Bookmark, CircleUserRound, EyeOff, LayoutDashboard, LogOut, Settings } from "lucide-react";

export type AppLayoutProps = {
  children?: ReactNode;
};

export const AppLayout = (props: AppLayoutProps) => {
  const user = useUser();
  const can = useAbilities();

  const navigation: NavigationItem[] = [
    {
      name: "Browse",
      href: route("browse.index"),
      active: route().current("browse.index"),
    },
  ];

  const userNavigation: UserNavigationItem[][] = useMemo(
    () => [
      ...(can?.access_admin_dashboard || can?.stop_impersonating
        ? [
            [
              ...(can?.access_admin_dashboard
                ? [
                    {
                      name: "Admin Dashboard",
                      icon: LayoutDashboard,
                      href: route("filament.admin.pages.dashboard"),
                      active: route().current("filament.admin.pages.dashboard"),
                      external: true,
                    },
                  ]
                : []),
              ...(can?.stop_impersonating // TODO: Re-enable this and update the route
                ? [
                    {
                      name: "Stop Impersonating",
                      icon: EyeOff,
                      href: route("filament-impersonate.leave"),
                      active: false,
                      external: true,
                    },
                  ]
                : []),
            ],
          ]
        : []),
      [
        {
          name: "Saves",
          icon: Bookmark,
          href: route("saves.index"),
          active: route().current("saves.index"),
        },
        {
          name: "Profile",
          icon: CircleUserRound,
          href: route("user.show", { user: user ?? "" }),
          active: route().current("user.show", { user: user ?? "" }),
        },
        {
          name: "Settings",
          icon: Settings,
          href: route("settings.account.index"),
          active: route().current()?.startsWith("settings") ?? false,
        },
        {
          name: "Log Out",
          icon: LogOut,
          href: "/login/0",
          active: false,
          method: "delete",
        },
      ],
    ],
    [can],
  );

  return (
    <BaseLayout>
      <ReactTooltip effect="solid" backgroundColor="#1f2937" className="!text-sm" />
      <Navbar navigation={navigation} userNavigation={userNavigation} />
      <div className="flex grow flex-col overflow-x-hidden pt-16">{props.children}</div>
    </BaseLayout>
  );
};
